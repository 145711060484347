// @flow
import * as React from 'react';

import AuthService from 'services/auth';

import { Route, Redirect } from 'react-router-dom';

type Props = React.ElementConfig<typeof Route>;

function PrivateRoute({ component: Component = null, ...rest }: Props) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (AuthService.isAuthenticated() === true) {
          return <Component {...props} />;
        }

        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
      }}
    />
  );
}

export default PrivateRoute;
