// @flow
import { ADD_PUSH_NOTIFICATION, DELETE_PUSH_NOTIFICATION } from '../actions/pushNotifications';

import type { PushNotification } from 'push-notification';

import type { Action } from '../actions/pushNotifications';

export type State = Array<PushNotification>;

const INITIAL_STATE: State = [];

const pushNotifications = (state: State = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case ADD_PUSH_NOTIFICATION:
      return [...state, action.notification];
    case DELETE_PUSH_NOTIFICATION:
      return [...state.slice(0, action.index), ...state.slice(action.index + 1)];
    default:
      return state;
  }
};

export default pushNotifications;
