// @flow
import {
  FETCH_CONFIGURATION_BEGIN,
  FETCH_CONFIGURATION_SUCCESS,
  FETCH_CONFIGURATION_FAILURE,
} from '../actions/configuration';

import type { Configuration } from 'configuration';

import type { Action } from '../actions/configuration';

export type State = {
  items: Configuration,
  loading: boolean,
  error: Object,
};

const INITIAL_STATE: State = {
  items: {},
  loading: false,
  error: null,
};

export default function reducer(state: State = INITIAL_STATE, action: Action): State {
  switch (action.type) {
    case FETCH_CONFIGURATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.configuration,
      };
    case FETCH_CONFIGURATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
