// @flow
import type { AffiliateSimple } from 'affiliate';

export const FETCH_AFFILIATES_BEGIN = 'FETCH_AFFILIATES_BEGIN';
export const FETCH_AFFILIATES_SUCCESS = 'FETCH_AFFILIATES_SUCCESS';
export const FETCH_AFFILIATES_FAILURE = 'FETCH_AFFILIATES_FAILURE';

type FetchAffiliatesBegin = {
  type: typeof FETCH_AFFILIATES_BEGIN,
};

type FetchAffiliatesSuccess = {
  type: typeof FETCH_AFFILIATES_SUCCESS,
  payload: { affiliates: AffiliateSimple[] },
};

type FetchAffiliatesFailure = {
  type: typeof FETCH_AFFILIATES_FAILURE,
  payload: { error: Object },
};

export type Action = FetchAffiliatesBegin | FetchAffiliatesSuccess | FetchAffiliatesFailure;

export function fetchAffiliatesBegin(): FetchAffiliatesBegin {
  return {
    type: FETCH_AFFILIATES_BEGIN,
  };
}

export function fetchAffiliatesSuccess(affiliates: AffiliateSimple[]): FetchAffiliatesSuccess {
  return {
    type: FETCH_AFFILIATES_SUCCESS,
    payload: { affiliates },
  };
}

export function fetchAffiliatesFailure(error: Object): FetchAffiliatesFailure {
  return {
    type: FETCH_AFFILIATES_FAILURE,
    payload: { error },
  };
}

export function fetchAffiliates() {
  return async (dispatch: (Action) => void) => {
    const API = (await import('services/api')).default;

    dispatch(fetchAffiliatesBegin());

    API.get('affiliates/simple')
      .then(({ data }) => dispatch(fetchAffiliatesSuccess(data)))
      .catch((err) => dispatch(fetchAffiliatesFailure(err)));
  };
}
