// @flow
import * as React from 'react';

import { Switch, Route } from 'react-router-dom';

import Loading from 'views/components/Loading';

import GuestRoute from 'views/utils/hoc/GuestRoute';
import PrivateRoute from 'views/utils/hoc/PrivateRoute';

const Login = React.lazy(() => import('views/pages/Login'));
const Registration = React.lazy(() => import('views/pages/Registration'));

const EmailConfirmation = React.lazy(() => import('views/pages/EmailConfirmation'));

const OfferPreview = React.lazy(() => import('screens/OfferPreview'));

const LoginAsAffiliate = React.lazy(() => import('screens/LoginAsAffiliate'));

const Main = React.lazy(() => import('./Main'));

function Container(): React.Node {
  return (
    <div className="container-fluid py-2">
      <React.Suspense fallback={<Loading />}>
        <Switch>
          <GuestRoute path="/login" component={Login} />
          <GuestRoute path="/registration" component={Registration} />
          <Route path="/offer-previews/:id" component={OfferPreview} />
          <GuestRoute path="/email-confirmation/:id" component={EmailConfirmation} />
          <PrivateRoute path="/login-as-affiliate/:id" component={LoginAsAffiliate} />
          <PrivateRoute component={Main} />
        </Switch>
      </React.Suspense>
    </div>
  );
}

export default Container;
