// @flow
import {
  FETCH_AFFILIATE_BALANCE_BEGIN,
  FETCH_AFFILIATE_BALANCE_SUCCESS,
  FETCH_AFFILIATE_BALANCE_FAILURE,
} from '../actions/affiliateBalance';

import type { Affiliate } from 'affiliate';

import type { Action } from '../actions/affiliateBalance';

export type State = {
  value: $PropertyType<Affiliate, 'balance'>,
  loading: boolean,
  error: Object,
};

const INITIAL_STATE: State = {
  value: 0,
  loading: false,
  error: null,
};

export default function reducer(state: State = INITIAL_STATE, action: Action): State {
  switch (action.type) {
    case FETCH_AFFILIATE_BALANCE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_AFFILIATE_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        value: action.payload.value,
      };
    case FETCH_AFFILIATE_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
