// @flow
import * as React from 'react';

import styled from 'styled-components';

import type { StyledComponent } from 'styled-components';

const Wrapper: StyledComponent<{}, {}, HTMLDivElement> = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 2em auto 1em;
`;

const HomeLink: StyledComponent<{}, {}, HTMLAnchorElement> = styled.a`
  color: #ffffff;
  border-color: #3ab0ff;
  background-color: #3ab0ff;
`;

function ApplicationError() {
  return (
    <Wrapper>
      <h1>Oh no, something went wrong!</h1>
      <p>
        If you need help please contact our tech support - <b>tech@vortexads.com</b>
      </p>
      <HomeLink href="/" className="btn btn-lg">
        Take me home
      </HomeLink>
    </Wrapper>
  );
}

export default ApplicationError;
