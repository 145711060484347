// @flow
import {
  FETCH_OFFERS_BEGIN,
  FETCH_OFFERS_SUCCESS,
  FETCH_OFFERS_FAILURE,
} from '../actions/offers';

import type { Action } from '../actions/offers';

export type State = {
  items: Map<number, string>,
  loading: boolean,
  error: Object,
};

const INITIAL_STATE: State = {
  items: new Map(),
  loading: false,
  error: null,
};

export default function reducer(state: State = INITIAL_STATE, action: Action): State {
  switch (action.type) {
    case FETCH_OFFERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OFFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.offers.reduce((acc, { _id, title }) => {
          acc.set(_id, title);

          return acc;
        }, new Map()),
      };
    case FETCH_OFFERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
