// @flow
import * as React from 'react';

import Links from './Links';

import type { JWTRole } from 'overclick';

type Props = {
  role: JWTRole,
};

function Navigation({ role }: Props): React.Node {
  switch (role) {
    case 'adm':
    case 'him':
    case 'ham':
    case 'inm':
    case 'afm':
      return <Links.Manager />;
    case 'aff':
      return <Links.Affiliate />;
    default:
      return null;
  }
}

export default Navigation;
