// @flow
import type { Configuration } from 'configuration';

export const FETCH_CONFIGURATION_BEGIN = 'FETCH_CONFIGURATION_BEGIN';
export const FETCH_CONFIGURATION_SUCCESS = 'FETCH_CONFIGURATION_SUCCESS';
export const FETCH_CONFIGURATION_FAILURE = 'FETCH_CONFIGURATION_FAILURE';

type FetchConfigurationBegin = {
  type: typeof FETCH_CONFIGURATION_BEGIN,
};

type FetchConfigurationSuccess = {
  type: typeof FETCH_CONFIGURATION_SUCCESS,
  payload: { configuration: Configuration },
};

type FetchConfigurationFailure = {
  type: typeof FETCH_CONFIGURATION_FAILURE,
  payload: { error: Object },
};

export type Action =
  | FetchConfigurationBegin
  | FetchConfigurationSuccess
  | FetchConfigurationFailure;

export function fetchConfigurationBegin(): FetchConfigurationBegin {
  return {
    type: FETCH_CONFIGURATION_BEGIN,
  };
}

// prettier-ignore
export function fetchConfigurationSuccess(configuration: Configuration): FetchConfigurationSuccess {
  return {
    type: FETCH_CONFIGURATION_SUCCESS,
    payload: { configuration },
  };
}

export function fetchConfigurationFailure(error: Object): FetchConfigurationFailure {
  return {
    type: FETCH_CONFIGURATION_FAILURE,
    payload: { error },
  };
}

export const fetchConfiguration = () => {
  return async (dispatch: (Action) => void) => {
    const API = (await import('services/api')).default;

    dispatch(fetchConfigurationBegin());

    API.get('configuration')
      .then(({ data }) => dispatch(fetchConfigurationSuccess(data)))
      .catch((err) => dispatch(fetchConfigurationFailure(err)));
  };
};
