// @flow
import * as React from 'react';

const DELAY = 300;

function Loading(): React.Node {
  const [pastDelay, setPastDelay] = React.useState<boolean>(false);

  React.useEffect(() => {
    const intervalId = setInterval(() => setPastDelay(true), DELAY);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (pastDelay === true) {
    return <div className="text-center">Loading...</div>;
  }

  return null;
}

export default Loading;
