// @flow

function currencyFormatter(value: number, currency: string = 'USD'): string {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency,
  });
}

export default currencyFormatter;
