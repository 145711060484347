// @flow
import type { Affiliate } from 'affiliate';

export const FETCH_AFFILIATE_BALANCE_BEGIN = 'FETCH_AFFILIATE_BALANCE_BEGIN';
export const FETCH_AFFILIATE_BALANCE_SUCCESS = 'FETCH_AFFILIATE_BALANCE_SUCCESS';
export const FETCH_AFFILIATE_BALANCE_FAILURE = 'FETCH_AFFILIATE_BALANCE_FAILURE';

type Balance = $PropertyType<Affiliate, 'balance'>;

type FetchAffiliateBalanceBegin = {
  type: typeof FETCH_AFFILIATE_BALANCE_BEGIN,
};

type FetchAffiliateBalanceSuccess = {
  type: typeof FETCH_AFFILIATE_BALANCE_SUCCESS,
  payload: { value: number },
};

type FetchAffiliateBalanceFailure = {
  type: typeof FETCH_AFFILIATE_BALANCE_FAILURE,
  payload: { error: Object },
};

export type Action =
  | FetchAffiliateBalanceBegin
  | FetchAffiliateBalanceSuccess
  | FetchAffiliateBalanceFailure;

export function fetchAffiliateBalanceBegin(): FetchAffiliateBalanceBegin {
  return {
    type: FETCH_AFFILIATE_BALANCE_BEGIN,
  };
}

export function fetchAffiliateBalanceSuccess(value: Balance): FetchAffiliateBalanceSuccess {
  return {
    type: FETCH_AFFILIATE_BALANCE_SUCCESS,
    payload: { value },
  };
}

export function fetchAffiliateBalanceFailure(error: Object): FetchAffiliateBalanceFailure {
  return {
    type: FETCH_AFFILIATE_BALANCE_FAILURE,
    payload: { error },
  };
}

export function fetchAffiliateBalance(id: $PropertyType<Affiliate, '_id'>) {
  return async (dispatch: (Action) => void) => {
    const API = (await import('services/api')).default;

    dispatch(fetchAffiliateBalanceBegin());

    API.get(`affiliates/${id}/balance`)
      .then(({ data }) => dispatch(fetchAffiliateBalanceSuccess(data)))
      .catch((err) => dispatch(fetchAffiliateBalanceFailure(err)));
  };
}
