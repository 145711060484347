// @flow
import * as React from 'react';

import ApplicationError from './views/screens/ApplicationError';

import RollbarErrorTracking from './RollbarErrorTracking';

type Props = {
  children: React.Node,
};

type State = {
  hasError: boolean,
};

class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: mixed, info: mixed) {
    if (process.env.NODE_ENV === 'production') {
      RollbarErrorTracking.logErrorInfo(info);
      RollbarErrorTracking.logErrorInRollbar(error);
    } else {
      console.error(error, info);
    }
  }

  render(): React.Node {
    if (this.state.hasError === true) {
      return <ApplicationError />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
