// @flow
import * as React from 'react';

import { connect } from 'react-redux';

import currencyFormatter from 'utils/formatters/currency';

import type { Affiliate } from 'affiliate';
import type { State as AffiliateBalanceState } from 'state/reducers/affiliateBalance';

type OwnProps = {||};

type Props = {
  ...OwnProps,
  value: $PropertyType<Affiliate, 'balance'>,
};

function AffiliateBalance({ value }: Props) {
  return <span>Balance: {currencyFormatter(value / 100)}</span>;
}

type State = {
  affiliateBalance: AffiliateBalanceState,
};

const mapStateToProps = ({ affiliateBalance }: State) => ({
  value: affiliateBalance.value,
});

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, {})(AffiliateBalance);
