// @flow
import type { Manager } from 'manager';

export const FETCH_MANAGERS_BEGIN = 'FETCH_MANAGERS_BEGIN';
export const FETCH_MANAGERS_SUCCESS = 'FETCH_MANAGERS_SUCCESS';
export const FETCH_MANAGERS_FAILURE = 'FETCH_MANAGERS_FAILURE';

type FetchManagersBegin = {
  type: typeof FETCH_MANAGERS_BEGIN,
};

type FetchManagersSuccess = {
  type: typeof FETCH_MANAGERS_SUCCESS,
  payload: { managers: Manager[] },
};

type FetchManagersFailure = {
  type: typeof FETCH_MANAGERS_FAILURE,
  payload: { error: Object },
};

export type Action = FetchManagersBegin | FetchManagersSuccess | FetchManagersFailure;

export function fetchManagersBegin(): FetchManagersBegin {
  return {
    type: FETCH_MANAGERS_BEGIN,
  };
}

export function fetchManagersSuccess(managers: Manager[]): FetchManagersSuccess {
  return {
    type: FETCH_MANAGERS_SUCCESS,
    payload: { managers },
  };
}

export function fetchManagersFailure(error: Object): FetchManagersFailure {
  return {
    type: FETCH_MANAGERS_FAILURE,
    payload: { error },
  };
}

export function fetchManagers() {
  return async (dispatch: (Action) => void) => {
    const API = (await import('services/api')).default;

    dispatch(fetchManagersBegin());

    API.get('managers')
      .then(({ data }) => dispatch(fetchManagersSuccess(data)))
      .catch((err) => dispatch(fetchManagersFailure(err)));
  };
}
