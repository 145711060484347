// @flow
import * as React from 'react';

import AuthService from 'services/auth';

import { Route, Redirect } from 'react-router-dom';

type Props = React.ElementConfig<typeof Route>;

function PublicRoute({ component: Component = null, ...rest }: Props) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (AuthService.isAuthenticated() === true) {
          return <Redirect to="/" />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

export default PublicRoute;
