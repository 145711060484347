// @flow
import * as React from 'react';

import styled from 'styled-components';

import type { StyledComponent } from 'styled-components';

const MyBanner: StyledComponent<{}, {}, HTMLDivElement> = styled.div`
  width: 300px;
  z-index: 9999;
  position: fixed;
  top: 15px;
  right: -399px;
  padding: 0.25rem 0.75rem;
  border-radius: 0.5rem;
  background-color: #f7f7f7;
  border: 1px solid #b1b1b1;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.55);
  transition: all 0.4s ease-out;
  &.visible {
    right: 15px;
  }
`;

type Props = {
  title: string,
  message: string,
  onDelete: () => void,
};

type State = {
  isVisible: boolean,
};

class Banner extends React.Component<Props, State> {
  state = {
    isVisible: false,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isVisible: true });
    }, 0);

    setTimeout(() => {
      this.setState({ isVisible: false });
    }, 2000);

    setTimeout(() => {
      this.props.onDelete();
    }, 4000);
  }

  render() {
    return (
      <MyBanner className={this.state.isVisible ? 'visible' : ''}>
        <div className="notification-message" role="alert">
          <h4 className="mb-0">{this.props.title}</h4>
          <div className="message">{this.props.message}</div>
        </div>
      </MyBanner>
    );
  }
}

export default Banner;
