export const STYLE = {
  height: '30px',
  fontSize: '14px',
  lineHeight: '30px',
};

export const HINT_STYLE = {
  bottom: 0,
  color: 'rgb(117, 117, 117)',
  fontSize: '14px',
  lineHeight: '30px',
};

export const TEXT_FIELD_STYLE = {
  width: '100%',
  height: '30px',
  fontSize: '14px',
  lineHeight: '30px',
};

export const MENU_ITEM_STYLE = {
  minHeight: '28px',
  fontSize: '14px',
  lineHeight: '28px',
};

export const UNDERLINE_STYLE = {
  bottom: '2px',
};
