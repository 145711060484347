// @flow
import { connect } from 'react-redux';

import AutoComplete from 'views/UI/material-ui/AutoComplete';

import type { State as OfferState } from 'state/reducers/affiliates';

type DataSourceObject = { value: number, text: string };

export type Request = DataSourceObject;

type DataSource = DataSourceObject[];

type OwnProps = {};

type Props = {
  ...OwnProps,
  dataSource: DataSource,
};

type State = {
  offers: OfferState,
};

const mapStateToProps = ({ offers }: State) => {
  const dataSource: DataSource = [];

  for (const [key, value] of offers.items) {
    dataSource.push({ value: key, text: `(${key}) ${value}` });
  }

  return { dataSource };
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, {})(AutoComplete);
