// @flow
import * as React from 'react';

import OfferAutoComplete from 'views/UI/inputs/autocomplete/Offer';
import AffiliateAutoComplete from 'views/UI/inputs/autocomplete/Affiliate';

import './index.scss';

const POPOVER_PROPS = {
  style: { width: '285px' },
};

function JumpTo() {
  const [type, setType] = React.useState<'affiliate' | 'offer'>('offer');

  const handleChange = (event) => {
    setType(event.currentTarget.value);
  };

  const handleNewRequest = ({ value }) => {
    if (value) {
      if (type === 'affiliate') {
        return window.open(`/affiliates/${value}`, '_blank');
      }

      if (type === 'offer') {
        return window.open(`/offers/${value}`, '_blank');
      }
    }
  };

  return (
    <div className="jump-to__container">
      <div className="jump-to__btns">
        <div className="jump-to__btns__btn">
          <input
            id="jump-to-affiliate"
            name="type"
            type="radio"
            value="affiliate"
            checked={type === 'affiliate'}
            onChange={handleChange}
          />
          <label htmlFor="jump-to-affiliate">A</label>
        </div>
        <div className="jump-to__btns__btn">
          <input
            id="jump-to-offer"
            name="type"
            type="radio"
            value="offer"
            checked={type === 'offer'}
            onChange={handleChange}
          />
          <label htmlFor="jump-to-offer">O</label>
        </div>
      </div>
      <div className="jump-to__input-container">
        {type === 'affiliate' && (
          <AffiliateAutoComplete
            fullWidth
            name="affiliate"
            hintText="Jump to affiliate"
            popoverProps={POPOVER_PROPS}
            onNewRequest={handleNewRequest}
          />
        )}
        {type === 'offer' && (
          <OfferAutoComplete
            fullWidth
            name="offer"
            hintText="Jump to offer"
            popoverProps={POPOVER_PROPS}
            onNewRequest={handleNewRequest}
          />
        )}
      </div>
    </div>
  );
}

export default JumpTo;
