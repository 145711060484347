// @flow
import type { PushNotification } from 'push-notification';

export const ADD_PUSH_NOTIFICATION = 'ADD_PUSH_NOTIFICATION';
export const DELETE_PUSH_NOTIFICATION = 'DELETE_PUSH_NOTIFICATION';

type AddPushNotifications = {
  type: typeof ADD_PUSH_NOTIFICATION,
  notification: PushNotification,
};

type DeletePushNotification = {
  type: typeof DELETE_PUSH_NOTIFICATION,
  index: number,
};

export type Action = AddPushNotifications | DeletePushNotification;

export function addPushNotification(notification: PushNotification): AddPushNotifications {
  return {
    notification,
    type: ADD_PUSH_NOTIFICATION,
  };
}

export function deletePushNotification(index: number): DeletePushNotification {
  return {
    index,
    type: DELETE_PUSH_NOTIFICATION,
  };
}
