// @flow
import {
  FETCH_MANAGERS_BEGIN,
  FETCH_MANAGERS_SUCCESS,
  FETCH_MANAGERS_FAILURE,
} from '../actions/managers';

import type { Action } from '../actions/managers';

export type State = {
  items: Map<number, string>,
  loading: boolean,
  error: Object,
};

const INITIAL_STATE: State = {
  items: new Map(),
  loading: false,
  error: null,
};

export default function reduce(state: State = INITIAL_STATE, action: Action): State {
  switch (action.type) {
    case FETCH_MANAGERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_MANAGERS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload.managers.reduce((acc, { _id, name }) => {
          acc.set(_id, name);

          return acc;
        }, new Map()),
      };
    case FETCH_MANAGERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
