// @flow
import * as React from 'react';

import AuthService from 'services/auth';

import { Link } from 'react-router-dom';

import Navigation from './Navigation';
import ProfileLink from './ProfileLink';

import logo from './logo.svg';

import './index.scss';

const Navbar = {
  LoggedIn: function () {
    const claims = AuthService.getClaims();

    const handleLogout = () => {
      AuthService.logout();

      window.location.replace(`${process.env.REACT_APP_MAIN_SITE_ORIGIN}/login`);
    };

    return (
      <React.Fragment>
        <ul className="navbar-nav nav-pills">
          <Navigation role={claims.rol} />
        </ul>
        <div className="d-flex align-items-center flex-nowrap ml-auto">
          <ProfileLink {...claims} />
          <button
            type="button"
            name="logout"
            className="btn btn-primary btn-sm mx-2"
            onClick={handleLogout}>
            Logout
          </button>
        </div>
      </React.Fragment>
    );
  },
  LoggedOut: function () {
    return (
      <div className="ml-auto">
        <Link to="/login" className="btn btn-primary mx-1">
          Login
        </Link>
        <Link to="/registration" className="btn btn-success mx-1">
          Registration
        </Link>
      </div>
    );
  },
};

function Header(): React.Node {
  const isAuthenticated = AuthService.isAuthenticated();

  return (
    <header className="Header">
      <nav className="navbar navbar-expand-lg navbar-light">
        <Link to="/" className="navbar-brand">
          <img src={logo} width="133" height="20" alt="Vortex Advertising" />
        </Link>
        <div className="collapse navbar-collapse">
          {isAuthenticated === true ? <Navbar.LoggedIn /> : <Navbar.LoggedOut />}
        </div>
      </nav>
    </header>
  );
}

export default Header;
